<template>
  <div>
    <v-treeview
      v-model="currentItems"
      selectable
      open-on-click
      transition
      :items="servicos"
      item-text="descricao"
      item-key="id"
      class="d-flex flex-column align-start text-left text-uppercase"
    >
    </v-treeview>
    <v-card-actions>
      <v-spacer />
      <buttons-cadastro @close="reset" @save="atualizarServicos" />
    </v-card-actions>
  </div>
</template>

<script>
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    contrato: Object,
  },
  components: { ButtonsCadastro },
  data() {
    return {
      currentItems: [],
      originalItems: []
    }
  },
  async created() {
    this.currentItems = this.contrato.parceria.servicos || []
    this.originalItems = this.contrato.parceria.servicos || []
  },
  computed: {
    ...mapState('patio', ['servicos']),
  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
    ]),
    ...mapActions('patio', ['getServicosAtivos']),
    ...mapActions('contratos', ['atualizarParceria']),
    reset() {
      this.currentItems = this.originalItems
    },
    async atualizarServicos() {
      try {
        await this.atualizarParceria({
          contrato: this.contrato.public_id,
          parceria: {
            ...this.contrato.parceria,
            servicos: this.currentItems,
          },
        })
        this.successMessage('Serviços atualizados com sucesso')
      } catch {
        this.errorMessage('Erro ao atualizar serviços')
      }
    }
  },
}
</script>
