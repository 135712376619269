<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Parceiro">
          <form-adicionar-contrato
            v-if="mostrarFormAdicionar || irParaCadastro"
            :perfilContrato="perfilContrato"
            @adicionado="mostrarFormAdicionar = false"
          />
          <form-editar-contrato
            v-else-if="editarContrato"
            v-model="contratoSelecionado"
          >
            <template #append-tab>
              <v-tab href="#terminais">
                Terminais <v-icon>mdi-widgets</v-icon></v-tab
              >
              <v-tab href="#servicos">
                Serviços <v-icon>mdi-file-table-box-multiple</v-icon></v-tab
              >
            </template>
            <template #tab-item>
              <v-tab-item value="terminais" eager>
                <list-terminal class="pa-4" :contrato="contratoSelecionado"/>
              </v-tab-item>
              <v-tab-item value="servicos" eager>
                <list-servico class="pa-4" :contrato="contratoSelecionado"/>
              </v-tab-item>
            </template>
          </form-editar-contrato>
          <listar-contratos
            v-else
            v-model="parceiros"
            :tela="permission"
            title="Parceiros registrados"
            titleNew="Novo Parceiro"
            :perfilContrato="perfilContrato"
            @mostrarFormAdicionar="mostrarFormAdicionar = true"
            @editar="editar"
            @update="getContratos"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ListarContratos from '@/components/contrato/ListarContratos'
import FormAdicionarContrato from '@/components/contrato/adicionar/FormAdicionarContrato.vue'
import FormEditarContrato from '@/components/contrato/editar/FormEditarContrato.vue'
import ListTerminal from '@/components/contrato/parceiro/ListTerminal.vue'
import ListServico from '@/components/contrato/parceiro/ListServico.vue'
import Perfil from '@/utils/perfil'
import { BasicPermissions, Sequences } from "@/utils/permissions"

export default {
  props: {
    irParaCadastro: {
      default: false,
    },
  },
  data() {
    return {
      Sequences,
      mostrarFormAdicionar: false,
      editarContrato: false,
      page: {
        title: 'Gerenciamento de Parceiros',
      },
      breadcrumbs: [
        {
          text: 'Geral',
          disabled: false,
        },
        {
          text: 'Parceiro',
          disabled: true,
        },
      ],
      perfilContrato: Perfil.Parceiro,
      contratoSelecionado: {},
    }
  },
  components: {
    ListarContratos,
    FormAdicionarContrato,
    FormEditarContrato,
    ListTerminal,
    ListServico
  },
  computed: {
    ...mapGetters('contratos', ['parceiros']),

    permission() {
      return Sequences.Parceiros.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    if (this.canList) {
      try {
        await this.getContratos(this.perfilContrato)
      } catch (e) {
        this.errorMessage('Erro ao carregar lista de empresas contratadas')
      }
    }
  },
  methods: {
    ...mapActions('contratos', ['getContratos']),
    ...mapActions('template', ['errorMessage']),

    editar(contrato) {
      this.contratoSelecionado = contrato
      this.editarContrato = true
    },
  },
}
</script>

<style></style>
