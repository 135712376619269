<template>
  <div>
    <v-treeview
      v-model="currentItems"
      selectable
      open-on-click
      transition
      :items="terminaisFornecedores"
      item-text="trade_name"
      item-key="public_id"
      class="d-flex flex-column align-start text-left"
    >
    </v-treeview>
    <v-card-actions>
      <v-spacer />
      <buttons-cadastro @close="reset" @save="atualizarTerminais" />
    </v-card-actions>
  </div>
</template>

<script>
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    contrato: Object,
  },
  components: { ButtonsCadastro },
  data() {
    return {
      currentItems: [],
      originalItems: []
    }
  },
  async created() {
    this.currentItems = this.contrato.parceria.terminais || []
    this.originalItems = this.contrato.parceria.terminais || []
    await this.buscarTerminaisFornecedores()
  },
  computed: {
    ...mapState('contratos', ['terminaisFornecedores']),
  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
    ]),
    ...mapActions('contratos', [
      'atualizarParceria',
      'buscarTerminaisFornecedores'
    ]),
    reset() {
      this.currentItems = this.originalItems
    },
    async atualizarTerminais() {
      try {
        await this.atualizarParceria({
          contrato: this.contrato.public_id,
          parceria: {
            ...this.contrato.parceria,
            terminais: this.currentItems,
          },
        })
        this.successMessage('Terminais atualizados com sucesso')
      } catch {
        this.errorMessage('Erro ao atualizar terminais')
      }
    }
  },
}
</script>
